import React, { useEffect, useState } from 'react';
import styles from './Header.module.css';
import { FaLinkedin, FaEnvelope, FaWindows, FaProjectDiagram, FaComments } from 'react-icons/fa';
import { useChat } from '../context/ChatContext';

function Header() {
  const [isVisible, setIsVisible] = useState(false);
  const { toggleChat } = useChat();

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <>
      <div className={styles.headerWrapper}>
        <header className={styles.header}>
          <div className={styles.container}>
            <div className={`${styles.intro} ${isVisible ? styles.visible : ''}`}>
              <h1 className={styles.name}>Siawash</h1>
              <h2 className={styles.title}>Software Engineer</h2>
            </div>
            <div className={`${styles.content} ${isVisible ? styles.visible : ''}`}>
              <p className={styles.summary}>Skilled IT specialist with experience in developing user-friendly software and delivering exceptional customer service.</p>
              <div className={styles.contact}>
                
                <a href="https://se.linkedin.com/in/siawash-sia-ysofzay-9b5a0b217" target="_blank" rel="noopener noreferrer" className={styles.iconLink}><FaLinkedin /></a>
                <div className={styles.emailContainer}>
                  <a href="mailto:Sia.Brev@gmail.com" className={`${styles.iconLink} ${styles.emailIcon}`}>
                    <FaEnvelope />
                    <span className={styles.emailTooltip}>Sia.Brev@gmail.com</span>
                  </a>
                </div>
              </div>
              <div className={styles.buttons}>
                <button onClick={() => document.getElementById('projects').scrollIntoView({ behavior: 'smooth' })} className={styles.button}>
                  <FaProjectDiagram /> Projects
                </button>
                <button onClick={toggleChat} className={styles.button}>
                  <FaComments /> Interactive CV
                </button>
                <button onClick={() => window.open('https://windows-xp-portfolio-omega.vercel.app/', '_blank')} className={styles.button}>
                  <FaWindows /> Windows
                </button>
              </div>
            </div>
          </div>
        </header>
      </div>
      <div id="chatbot-anchor"></div>
    </>
  );
}

export default Header;
