import React, { useState } from 'react';
import styles from './Projects.module.css';
import medAppImage from '../images/medApp.jpeg';
import goodsReceiverGif from '../images/goods.gif';

function Projects() {
  const [selectedProject, setSelectedProject] = useState(null);

  const projects = [
    { 
      id: 1, 
      title: 'LastBil', 
      description: 'Python-Selenium automation tool for streamlining RITM closure tasks in ServiceNow, significantly reducing processing time and manual work.',
      details: 'Developed a tool that reduces processing time from 25 seconds to instant per task. It handles bulk processing, delegate pickups, and equipment collection scenarios, saving approximately 5 hours of manual work per month per IT staff. Features include real-time status tracking and HTML report generation for verification.'
    },
    { 
      id: 2, 
      title: 'Meditation App', 
      description: 'A React Native app featuring meditation timer and guided breathing exercises.',
      details: 'Developed while learning React Native, this app includes a timer for meditation sessions and a queue-based breathing exercise that guides users on when to inhale and exhale. Although not yet published, it serves as a practical tool for personal use and for friends. The project showcases my ability to create mobile applications with React Native and implement features like timers and guided exercises.',
      image: medAppImage
    },
    { 
      id: 3, 
      title: 'Signature System', 
      description: 'A digital signature platform built with Django to replace traditional paper forms.',
      details: 'Developed a web-based system to digitize and catalogue receipts, allowing users to sign on a screen instead of paper. This environmentally conscious solution makes it easier to keep track of items while reducing paper waste. The platform streamlines the process of managing signatures and associated documents, improving efficiency and organization.',
      techStack: 'Django, Python, HTML, CSS, JavaScript',
    },
    { 
      id: 4, 
      title: 'Goods Receiver', 
      description: 'Python-Selenium automation tool for registering IT-related equipment arrivals, streamlining the verification process.',
      details: 'Automated the process of manually registering IT-related equipment that arrived daily. This tool significantly reduces the time and effort required for equipment registration, saving multiple steps in the process. It also implements a verification system allowing technicians or admins to easily check the results, eliminating tedious and boring manual work. The project demonstrates proficiency in process automation and improving workflow efficiency.',
      techStack: 'Python, Selenium, HTML',
      image: goodsReceiverGif
    },
  ];

  const openProject = (project) => {
    setSelectedProject(project);
  };

  const closeProject = () => {
    setSelectedProject(null);
  };

  return (
    <section id="projects" className={styles.projects}>
      <h2 className={styles.sectionTitle}>Projects</h2>
      <div className={styles.projectsGrid}>
        {projects.map((project) => (
          <div key={project.id} className={styles.projectCard} onClick={() => openProject(project)}>
            <h3>
              {project.id === 1 && <span role="img" aria-label="truck">🚚</span>}
              {project.id === 2 && <span role="img" aria-label="meditation">🧘</span>}
              {project.id === 3 && <span role="img" aria-label="signature">✍️</span>}
              {project.id === 4 && <span role="img" aria-label="package">📦</span>}
              {project.title}
            </h3>
            <p>{project.description}</p>
          </div>
        ))}
      </div>
      {selectedProject && (
        <div className={styles.modal} onClick={closeProject}>
          <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
            <button className={styles.closeButton} onClick={closeProject}>&times;</button>
            <h3>{selectedProject.title}</h3>
            <p>{selectedProject.details}</p>
            {selectedProject.techStack && (
              <p><strong>Tech Stack:</strong> {selectedProject.techStack}</p>
            )}
            {selectedProject.image && (
              <img 
                src={selectedProject.image} 
                alt={selectedProject.title} 
                className={styles.projectImage}
              />
            )}
          </div>
        </div>
      )}
    </section>
  );
}

export default Projects;
