import React from 'react';
import styles from './Experience.module.css';
import { FaBriefcase, FaTools, FaCode } from 'react-icons/fa';

function Experience() {
  return (
    <section className={styles.experience}>
      <h2 className={styles.sectionTitle}><FaBriefcase /> Experience</h2>
      <div className={styles.experienceItem}>
        <h3>IT technician/software developer</h3>
        <h4>Volvo Group/HCL/infocare</h4>
        <p className={styles.date}>2022 - Present</p>
        <p>As an IT Specialist at Volvo Group, my primary responsibilities are centered around computer troubleshooting, providing assistance to users, and delivering exceptional customer service. My expertise allowed me to efficiently address technical issues related to PCs and services, ensuring a seamless user experience.</p>
        <p>While fulfilling these official duties, I identified various opportunities to enhance workflows and minimize repetitive tasks for both myself and my colleagues. Driven by a proactive mindset, I sought to develop innovative solutions that would optimize our day-to-day operations.</p>
    
      </div>
      
      <div className={styles.experienceItem}>
        <h3>Summer Job: Forklift driver (battery storage)</h3>
        <h4>Volvo Cars</h4>
        <p className={styles.date}>2022 mar-nov</p>
        <p>Was hired as a summer worker/flex in a battery storage for Volvo Cars. The main responsibilities were to ship orders and load them into trucks.</p>
      </div>
      
      <div className={styles.experienceItem}>
        <h3>Math Tutor</h3>
        <h4>Sweden (Trollhättan)</h4>
        <p className={styles.date}>2016</p>
        <p>Worked as math coach with students that were struggling with high-school math in a temporary summer school.</p>
      </div>
    </section>
  );
}

export default Experience;