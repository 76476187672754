import React from 'react';
import Header from './components/Header';
import Chatbot from './components/Chatbot';
import Education from './components/Education';
import Experience from './components/Experience';
import Projects from './components/Projects';
import './App.css';
import { ChatProvider } from './context/ChatContext';

function App() {
  return (
    <ChatProvider>
      <div className="app">
        <Header />
        <Chatbot />
        <div className="content">
          <main className="mainContent">
            <Experience />
            <Projects />
          </main>

          <aside className="sidebar">
            <Education />
          </aside>
        </div>
      </div>
    </ChatProvider>
  );
}

export default App;
