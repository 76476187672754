import React from 'react';
import styles from './Education.module.css';
import { FaGraduationCap, FaTools, FaLanguage, FaHeart, FaBrain, FaRobot, FaDatabase, FaReact, FaFireAlt } from 'react-icons/fa';
function Education() {
  return (
    <section className={styles.education}>
      <h2 className={styles.sectionTitle}><FaGraduationCap /> Education</h2>
      <div className={styles.educationItem}>
  <h3>BSc Computer Engineering Technology</h3>
  <h4>University West</h4>
  <p>Graduated: 2022</p>
  </div>
      
      <h3 className={styles.subTitle}><FaTools /> Skills & Tools</h3>
      <ul className={styles.skillsList}>
        <li>Python</li>
        <li>C++</li>
        <li>JavaScript</li>
        <li>Django</li>
        <li>Git & Github</li>
        <li>Unit Testing</li>
        <li>Machine Learning</li>
        <li>Computer Vision</li>
      </ul>
      <h3 className={styles.subTitle}><FaBrain /> Currently Learning</h3>
      <ul className={styles.learningList}>
        <li><FaRobot /> Creating autonomous agents</li>
        <li><FaDatabase /> RAG applications</li>
        <li><FaReact /> UI design with React</li>
        <li><FaRobot /> Langchain</li>
        <li><FaFireAlt /> BaaS with Firebase</li>
      </ul>
      <h3 className={styles.subTitle}><FaLanguage /> Languages</h3>
      <ul className={styles.languagesList}>
        <li>English (Professional)</li>
        <li>Swedish (Native)</li>
        <li>Farsi/Dari (Native)</li>
        <li>Urdu (Intermediate)</li>
      </ul>
      
      <h3 className={styles.subTitle}><FaHeart /> Interests</h3>
      <ul className={styles.interestsList}>
        <li>Filmmaking</li>
        <li>Programming</li>
        <li>Puzzles</li>
        <li>Writing</li>
        <li>Building Apps</li>
      </ul>
    </section>
  );

}

export default Education;